<template>
  <div id="admin-song">
    <b-row no-gutters>
      <b-col>
        <label class="pl-2" v-if="!editing">
          <i class="text-danger mx-2 fas fa-times" @click="onDelete"></i>
          <i class=" mx-2 text-primary far fa-edit" @click="onEdit"></i>
          {{song.filename}}
        </label>
        <b-form-input v-if="editing"
                      v-model="songFilename"
                      @keyup.native.enter="update"
                      @keyup.native.esc="cancelEditing"
                      autocomplete="off">
        </b-form-input>
      </b-col>
      <b-col>
      <toggle-button class="large-font"
          id="premiumType"
          v-model="isPremium"
          @change="tagMusic"
          :sync="true"
          :width="105"
          :height="25"
          :labels="{checked: 'FREE', unchecked: 'PREMIUM'}"/>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <vue-plyr>
          <audio>
            <source :src="song.url" type="audio/mp3"/>
          </audio>
        </vue-plyr>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    props : {
      song    : Object,
      albumId : Number,
    },
    name : 'Song',
    data() {
      return {
        songFilename : null,
        editing      : false,
        isPremium    : false,
      };
    },
    mounted() {
      this.songFilename = this.song.filename;
      this.checkMusic();
    },
    methods : {
      ...mapActions({
        'removeAlbumSong' : 'media/removeAlbumSong',
      }),
      tagMusic(){
        if(this.isPremium){
          this.$http.post('/api/tag/music', {            
            tagId         : 1,
            mobileMusicId : this.song.id,
          })
        } else {
          this.$http.delete('/api/tag/music', { 
            data : {
              tagId         : 1,
              mobileMusicId : this.song.id,
            }
          })
        }
      },
      checkMusic(){
        this.$http.get('/api/tag/music/check',{
          params : {
            tagId         : 1,
            mobileMusicId : this.song.id,
          }
        }).then( res => {
          this.isPremium = res.data.isPremium
        });
      },

/**
       * On Edit Song
       */
      onEdit() {
        this.editing = true;
      },

      /**
       * Update Song
       *
       * @param value
       */
      // eslint-disable-next-line no-unused-vars
      update(value) {
        this.$http.put('/api/song', {
          mobileMusicId : this.song.id,
          updateFields  : {
            // eslint-disable-next-line camelcase
            music_title : this.songFilename,
          },
        }).then(() => {
          this.editing = false;
          this.song.filename = this.songFilename;
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated successfully',
          });
          this.$emit('updateSong');
        }).catch((err) => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : err.response.data[0].message || err.response.data.message,
          });
        });
      },

      /**
       * Cancel Song Edit
       */
      cancelEditing() {
        this.editing = false;
      },

      /**
       * Delete Song
       */
      onDelete() {
        this.$modal.show('dialog', {
          title : 'Please confirm:',
          text  : ' Are you sure you want to delete ' +
            this.song.filename + '?',
          buttons : [
            {
              title   : 'Yes', handler : () => {
                this.$http.delete('/api/song', {
                  data : {
                    id      : this.song.id,
                    albumId : this.albumId,
                  },
                }).then(() => {
                  this.$modal.hide('dialog');
                  this.removeAlbumSong(this.song);
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted ' + this.song.filename + ' successfully!',
                  });
                });
              },
            },
            { title : 'No' },
          ],
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/mobile_music/song";
</style>
